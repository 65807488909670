* {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  box-sizing: border-box;
  color: inherit;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}
ul {
  list-style: none;
}
.preload * {
  transition: none !important;
}
a {
  color: inherit;
  cursor: pointer;
  font-size: inherit;
}
img {
  display: block;
  max-width: 100%;
}
button {
  background: none;
  cursor: pointer;
}
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
}
select option {
  color: #000 !important;
}
html,
body {
  min-height: 100%;
  width: 100%;
  background-color: rgb(211, 216, 219);
}
html body,
body body {
  display: flex;
  flex-flow: column nowrap;
  min-height: 100vh;
  position: relative;
}
html body .containment,
body body .containment {
  flex: 1 0 auto;
}

.__wrap {
  height: 100%;
  margin: 0 auto;
  position: relative;
  max-width: 1200px;
}
